
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import OverviewNotice from './overview-notice.vue';
import WarningMessage from '@/components/warning-message.vue';
import SummaryIrLoi from '@/components/summary-ir-loi.vue';
interface SummaryData {
  estimate: number,
  actual: number,
  loaded: boolean,
}

interface Summary {
  IR: SummaryData,
  LOI: SummaryData,
}

@Component({
  components: {
    SummaryIrLoi,
    WarningMessage,
    OverviewNotice,
  },
})
export default class Overview extends Vue {
  calendarIsOpen = false;

  group: Summary = this.dataInit();
  user: Summary = this.dataInit();
  warningSet = new Set();
  warning = false;

  dataInit() {
    return {
      IR: {
        estimate: 0,
        actual: 0,
        loaded: false,
      },
      LOI: {
        estimate: 0,
        actual: 0,
        loaded: false,
      },
    };
  }

  async created() {
    // MARK: 로그인 한 뒤
    if (this.$route.params.login) {
      const { message } = this.$route.params;
      this.successMessage(message);
    }
    if (!this.checkStartDate()) {
      this.projectStartDate = moment().startOf('month').format('YYYY-MM-DD');
    }

    if (!this.checkEndDate()) {
      this.projectEndDate = moment().format('YYYY-MM-DD');
    }

    await this.warningSetInit();
  }

  checkStartDate() {
    new Date()
    return this.projectStartDate
        && this.projectEndDate
        && moment(this.projectStartDate).isSameOrAfter(moment(this.projectEndDate).add(-180, 'd'))
  }

  checkEndDate() {
    return this.projectEndDate
        && this.projectStartDate
        && moment(this.projectEndDate).isSameOrBefore(moment(this.projectStartDate).add(180, 'd'))
  }

  async load(dataType: 'IR' | 'LOI', onlyMe: boolean) {
    this.loading = true;
    const apiPath = dataType === 'IR' ? 'incidence-rate' : 'length-of-interview';
    try {
      const { result, data, message } = await this.axios.get(`overview/${ apiPath }`, {
        params: {
          onlyMe: onlyMe || null,
          startDate: this.projectStartDate,
          endDate: this.projectEndDate,
        },
      });

      if (!result) throw message;

      const { estimate, actual, warning } = data;
      const userType = onlyMe ? 'user' : 'group';

      this[userType][dataType].estimate = estimate;
      this[userType][dataType].actual = actual;
      this[userType][dataType].loaded = true;

      this.warningSet.add(warning);
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }

  async dateChange(): Promise<void> {
    this.group = this.dataInit();
    this.user = this.dataInit();

    await this.warningSetInit();
  }

  async warningSetInit() {
    this.warningSet.clear();
    await Promise.all([
      this.load('IR', false),
      this.load('LOI', false),
      this.load('IR', true),
      this.load('LOI', true),
    ]);
    this.warning = this.warningSet.has(true);
  }

  closeOverviewMessage(): void {
    this.$store.commit('setOverviewMessage', false);
  }

  get overviewMessage(): string {
    return this.$store.getters.overviewMessage;
  }

  get dateRange() {
    let endDate = moment(this.projectStartDate).add(180, 'd').format('YYYY-MM-DD')
    endDate = endDate > this.projectEndDate ? endDate : this.projectEndDate;
    return {
      start: {
        min: moment(this.projectEndDate).add(-180, 'd').format('YYYY-MM-DD'),
      },
      end: {
        max: endDate,
      },
    };
  }
}
