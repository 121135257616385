
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class SummaryIrLoi extends Vue {
  @Prop() _loading!: boolean;
  @Prop() user!: string;
  @Prop() dataType!: string;
  @Prop() IR!: number;
  @Prop() LOI!: number;
}
