
import { Component, Vue } from 'vue-property-decorator';

interface Messages {
  content: string,
  order: number,
  read: boolean,
  readDate: string | null,
  regDate: string,
  sendUserId: string,
  type: string,
  userId: string,
  _id: string,
}

interface Response {
  count: {
    announcement: number,
    panelInquiry: number,
    total: number,
  },
  messages: []
}

@Component
export default class OverviewNotice extends Vue {
  private announcement = 0;
  private panelInquiry = 0;
  private total = 0;

  async created(): Promise<void> {
    await this.load()
  }

  async load(): Promise<void> {
    this.loading = true;
    try {
      const { result, data, message } = await this.axios.get('/notice',{});
      if (!result) throw message;

      const { count } = data as Response;
      const { announcement, panelInquiry, total } = count;
      this.announcement = announcement;
      this.panelInquiry = panelInquiry;
      this.total = total
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }
}
